@import "_global_variables.scss";
@import "bootstrap/scss/bootstrap.scss";

@import "utilities/mixins";
@import "utilities/functions";
@import "utilities/spacings";
@import "utilities/images";
@import "utilities/fonts";
@import "utilities/colors";
@import "utilities/borders";
@import "utilities/controls";
@import "layout/layout";
@import "personal/personal";
@import "personal/fido";
@import "personal/totp";

* {
    font-family: "Roboto", sans-serif;
}

html, body {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    font-size: 16px;
}

body {
    overflow-y: scroll;
    margin: 0;
    font-size: 18px;
}

html {
    min-height: 100%;
}

.register-background {
    background: $background-image no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: rgba(0, 0, 0, .25);
}

.register-form-container {
    background-color: $white;
    max-width: 406px;
    width: 100%;
    padding: 2rem 3rem;
    border-radius: 1rem;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    border-color: $gray-brightest;
    background-color: $gray-brightest;
    color: $gray-light;
}

.text-link:hover {
    text-decoration: underline;
}

.text-link {
    @include font-bolder;
    color: $surfie-green;
}

.rotate-180 {
    transform: rotate(180deg);
}

.personal-container {
    width: 70%;    
    border-left: $border;
    border-right: $border;
    background-color: $main-first-background-color;
    min-height: 100vh;
    margin: auto;
}

@media all and (max-width: 1210px){
    .personal-container {
        width: 100%;
    }
}

.modal-dialog {
    max-width: 420px;
    margin-right: auto;
    margin-left: auto;
}

.modal-body {
    padding: 1.3rem 3.3rem;
}

@media screen and (max-width: 360px){
    .modal-body {
        padding: 1rem;
    }
    .modal-backdrop {
        min-width: 350px;
        min-height: 100%;
    }
}

.modal-content {
    border-radius: 12px;
}

.spinner {
    @include large-icon;
    background-image: $spinner;
}

.bucket-icon {
    @include normal-icon;
    background-image: $bucket-icon;
}

.edit-icon {
    @include small-icon;
    background-image: $edit-icon;
}

.arrow-icon {
    @include tiny-icon;
    background-image: $arrow-icon;
}

.fade-modal {
    animation-name: fade-modal;
    animation-duration: 1s;
}

@keyframes fade-modal {
    from {
        opacity: .0;
    }
  
    to {
        opacity: 1;
    }
  }

.text-inside-line {
    overflow: hidden;
    text-align: center;

    &::after, &::before {
        background-color: #D9D9D9;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    &::before {
        right: 0.5em;
        margin-left: -50%;
    }

    &::after {
        left: 0.5em;
        margin-right: -50%;
    }
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.modal-open:not(:has(.modal-backdrop)) {
    padding-right: 0px !important;
}

.w-fit-content {
    width: fit-content;
}

.scrollbar-hidden {
    scrollbar-width: none;
}