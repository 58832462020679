@include class-generator('opacity', 'opacity', (0.3, 0.68), '');

$gray:#444444;
$gray-light:#808080;
$gray-fade: #999999;
$gray-tint: #c5c5c7;
$gray-lighten:#EDEDED;
$gray-brightest:#dfdfdf;

$white: #ffffff;
$update-background:rgba(68, 68, 68, 0.05);

$surfie-green-dark:#2b4240;
$surfie-green:#117788;
$surfie-green-light:#007788;

$red-fade: #EC2347;
$red:#dd2233;
$green: #41AD49;

$red-light:#f07d64;
$red-lighten:#fbe8ea;

$yellow: #ffd966;

$main-first-background-color: $white;
$main-second-background-color: $gray-lighten;

$selected-device-background-color:$gray-lighten;
$device-font-color:$gray;
$form-warning-color:$gray-light;

$selected-device-info-background-color:$surfie-green;
$selected-device-divider-color:rgba(255, 255, 255, 0.1);

$error-color: $red;
$error-background-color: $red-lighten;
$border-color: $gray-brightest;

$key-container-red:$red-light;
$key-container-green:$surfie-green-light;
$key-container-dark:$surfie-green-dark;

.bg-surfie-green {
    background-color: $surfie-green;   
}

.text-surfie {
    color: $surfie-green;
}

.text-charcoal {
    color: #344252;
}

.text-orange {
    color: #FF9C29;
}

.text-arsenic {
    color: #3C3C43; 
}