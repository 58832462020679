.layout-container {
    width: 70%;    
    border-left: $border;
    border-right: $border;
    background-color: $main-first-background-color;
    min-height: 100vh;
    margin: auto;
    padding: 0 2rem;
    &.layout-container--auth {
        border:none;
        background-color: transparent;
    }
}



.layout-logo {
    background: $logo-image;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 50px;

}



@media all and (max-width: 992px) {
    .layout-logo {
        
    }
}

@media all and (max-width: 1210px){
    .layout-container {
        width: 100%;
    }
}
