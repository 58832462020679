@include class-generator('fs', 'font-size', (0.75, 0.875, 1, 1.125, 1.5, 1.75), 'rem');
@include class-generator('lh', 'line-height', (1.25), 'rem');
@include class-generator('fw', 'font-weight', (600), '');

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto'), url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto'), url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto'), url('../fonts/Roboto-Bold.ttf') format('truetype');
}

@mixin font-normal(){
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@mixin font-bold(){
    font-weight: 700 !important;
    font-style: normal;
    font-stretch: normal;
}

@mixin font-w-600(){
    font-weight: 600 !important;
    font-style: normal;
    font-stretch: normal;
}

@mixin font-bolder(){
    font-weight: 500 !important;
    font-style: normal;
    font-stretch: normal;
}

.fw-bold {
    @include font-bold();
}

.fw-bolder {
    @include font-bolder();
}

.fw-normal {
    @include font-normal();
}
