.Switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }
  
  .Switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .Slider {
    width: 40px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #808080;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .Slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: #808080;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .Slider {
    border: 1px solid #007788;
    background-color: #007788;
  }
  

  
  input:checked + .Slider:before {
    background-color: #fff;
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  .Slider.Round {
    border-radius: 34px;
  }
  
  .Slider.Round:before {
    border-radius: 50%;
  }