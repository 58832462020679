.personal {
    margin-top: 60px;
    margin-bottom: 1.5rem;
}

.personal-heading {
    font-size: 2rem;
    margin-bottom: 2rem;
    @include font-w-600;
}

.personal-info {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 2rem;
    @include font-bolder();
}

.personal-info__logo {
    background: $user-image;
    @include normal-icon;
}

.personal-info__name {
    font-size: 1.5rem;
}

.personal-logout {
    font-size: 1rem;
    color: $gray-tint;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.3s;
    padding: 0.5rem;
    &:hover > .personal-logout__text {
        color:$red;
    }
    .logout-icon-hover {
        max-height: 28px;
        display: none;
    }
    &:hover > .logout-icon-hover {
        display: block;
    }
    &:hover > .personal-logout__logo {
        display: none;
        background: none;
    }
    &:hover #logout-animation {
        animation-play-state: running;
    }
    &:hover {
        color: $red-fade;
        background-color: $gray-lighten;
        border-radius: $border-radius;
    }
}

#logout-animation {
    animation: logout-animation-frame 200ms linear 1 normal forwards;
    animation-play-state: paused;
}
@keyframes logout-animation-frame {
    0% {
        transform: translate(0px,0px)
    } 100% {
        transform: translate(3px,0px)
    }
}

.bucket-block {
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    &:hover > .bucket-icon > .bucket-icon-hover {
        display: block;
    }    
    &:hover > .bucket-icon {
        background: none;
    }
    &:hover #bucket-animation {
        animation-play-state: running;
    }
    &:hover {
        background: $gray-lighten;
    }
    .bucket-icon-hover {
        display: none;
    }
}

#bucket-animation {
    animation: bucket-animation-frame 200ms linear 1 normal forwards;
    animation-play-state: paused;
}
@keyframes bucket-animation-frame {
    0% {
        transform: translate(4.076012px,6.978964px) rotate(0deg)
    } 100% {
        transform: translate(4.076012px,6.978964px) rotate(-10.318974deg)
    }
}

.personal-logout__logo {
    background: $logout-image;
    @include normal-icon;
}

.personal-expiration {
    display: flex;
    gap: 5px;
}

.personal-expiration__text {
    color: $gray-light;
}

.personal-two-factor {
    display: flex;
    gap: 5px;
}

.personal-two-factor__text {
    color: $gray-light;
}

.personal-two-factor__value {
    color: $red;
}

.personal-two-factor__value--on {
    color: $green;
}

.personal-two-factor-heading {
    padding: 4px;
    font-size: 1.5rem;
    @include font-w-600;
    padding: 0.5rem 0 0.5rem 0;
}

.personal-two-factor-blocks {
    display: flex;
    gap: 20px;
}

.personal-two-factor-block {
    flex: 1 1 auto;
    height: 130px;
    border: $border;
    border-radius: $border-radius;
    font-size: 2rem;
    @include font-bolder();
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    & .personal-two-factor-block--done {
        position:absolute;
        background: $done-icon;
        bottom: 1rem;
        right: 1rem;
        @include normal-icon;
    }
}

.personal-add-device-header {
    font-size: 1.25rem;
    @include font-w-600;
}

.personal-add-device-step {
    @include font-bolder;
    color: $gray-tint;
    font-size: 0.875rem;
}

.item-device {
    border: 1px solid rgba(100, 100, 100, 0.16);
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0px;
}

.personal-qrcode__link {
    background: $qrCodelink;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 171px;
    height: 171px;
}

div[disabled]
{
    pointer-events: none;
    opacity: 0.7;
}